<template>
  <el-dialog
    title="日志详情"
    :visible="modalShow"
    @close="closeModal()"
    :close-on-click-modal="false"
    width="650px"
    center>
   <el-table
      :header-cell-style="{'text-align': 'center', 'height':'10px','background':'#fff','color':'#606266'}"
      :cell-style="{ 'text-align': 'center' }"
      :data="data"
      border
      class="margin-t10"
      :highlight-current-row='false'>
        <el-table-column label="标题" width="80">日志</el-table-column>
        <el-table-column label="日志" width="400" prop="summary"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope" >
            <a :href="formatUrl(scope.row.attachment)" class="cursor" v-if="scope.row.attachment" target="_blank">下载日志文件</a>
          </template>
        </el-table-column>
      </el-table> 
    <div class="text-center margin-t30  margin-b10">
      <el-button size="medium" type="info" @click="closeModal()">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    modalShow: Boolean,
    data:Array,
  },
  data(){
    return{
    }
  },
  computed: {
    formatUrl() {
      return function (link) {
        // 小米的直接跳转
        let url = new URL(link);
        if (url && url.hostname && url.hostname.indexOf('xiaomi') !== -1) {
          return link
        } else {
          return configBaseURL + '/api/tool/download?fileUrl=' + link;
        }
      }
    }
  },
  methods:{
    // 关闭弹框
    closeModal() {
      this.$emit("closeModal", false);
    }
  }
}
</script>

<style  scoped lang="scss">
 .logBox{
  margin-bottom: 20px;
 }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 10px 40px 24px;
  }
  .cursor{
    cursor:pointer;
    color: #546cff;
  }
</style>

