<template>
  <div class="pageBox">
    <div class="content">
      <div class="appInfoBox">
        <div class="imgBox">
          <el-image class="apkIcon" :src="detailsData.apkIcon">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="textBox">
          <p>测试项：<span>{{ formData.title }}</span></p>
          <p>测试编号：<span>{{ detailsData.code }}</span></p>
          <p>测试标准：<span>{{ detailsData.name }}</span></p>
        </div>
      </div>
      <el-table
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          class="tableBox W100"
          :data="detailsData.list">
        <el-table-column label="测试方" prop="memberName"></el-table-column>
        <el-table-column label="测试机型" prop="device"></el-table-column>
        <el-table-column label="android版本" prop="androidVersion"></el-table-column>
        <el-table-column label="测试结果" prop="state">
          <template slot-scope="scope">
            <svg-icon class="svg-review" :svg-name="scope.row.state | versionsTask_memberState()"></svg-icon>
          </template>
        </el-table-column>
        <el-table-column label="测试值" prop="value"
                         v-if="['性能测试','功耗测试'].indexOf(standardName) !== -1"></el-table-column>
        <el-table-column label="日志" align="left" v-if="['兼容性测试','稳定性测试'].indexOf(standardName) !== -1">
          <template slot-scope="scope">
            <template>
              <p v-if="scope.row.detail.length>0"><a class="cursor" @click="onLogDetails(scope.row.detail)">查看</a></p>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="device"
                         v-if="['兼容性测试','稳定性测试','大屏标准'].indexOf(standardName) !== -1">
          <template slot-scope="scope">
            <template v-for="(item,index) in scope.row.attachment">
              <p v-if="item.type==1" :key="index">
                <a class="cursor" v-if="item.url" :href="formatUrl(item.url)" target="_blank">LOG下载</a>
              </p>
              <p v-if="item.type==2" :key="index">
                <a class="cursor" v-if="item.url" :href="formatUrl(item.url)" target="_blank">截图下载</a>
              </p>
              <p v-if="item.type==3" :key="index">
                <a class="cursor" v-if="item.url" :href="formatUrl(item.url)" target="_blank">录屏下载</a>
              </p>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchFormData.perPage"
            :current-page="searchFormData.page"
            layout="total, prev, pager, next, sizes"
            :total="total"
        ></el-pagination>
      </div>
    </div>
    <log-details v-if="isShowlModal" :modalShow="isShowlModal" :data="logData" @closeModal="closeModal()"></log-details>
  </div>
</template>

<script>
import {singleGoldIdentificationSever} from "@/common/api-config";
import LogDetails from './template/logDetails'
import store from '@/store/index.js';

export default {
  beforeRouteEnter: (to, from, next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {
      'menu': to.meta.menuBreadcrumb,
      name: to.query.breadcrumbName,
      path: to.fullPath
    });
    next()
  },
  components: {LogDetails},
  data() {
    return {
      isShowlModal: false,
      formData: JSON.parse(decodeURIComponent(this.$route.query.formData)),
      detailsData: {},
      logData: [],
      title: this.$route.query.title,
      total: 0,
      searchFormData: {
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    standardName() {
      console.log(this.formData.standard);
      let standardName = '';
      switch (this.formData.standard) {
        case 'compatible':
          standardName = '兼容性测试'
          break;
        case 'stable':
          standardName = '稳定性测试'
          break;
        case 'security':
          standardName = '安全测试'
          break;
        case 'consumption':
          standardName = '功耗测试'
          break;
        case 'performance':
          standardName = '性能测试'
          break;
        case 'large':
          standardName = '大屏标准'
          break;
      }
      return standardName; //获取用户信息
    },
    formatUrl() {
      return function (link) {
        // 小米的直接跳转
        let url = new URL(link);
        if (url && url.hostname && url.hostname.indexOf('xiaomi') !== -1) {
          return link
        } else {
          return configBaseURL + '/api/tool/download?fileUrl=' + link;
        }
      }
    }
  },
  created() {
    this.getdetails();
  },
  methods: {
    getdetails() {
      singleGoldIdentificationSever.queryTermDetail({...this.formData, ...this.searchFormData}).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total;
          this.detailsData = res.data.data;
        }
      })
    },
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },
    onLogDetails(data) {
      this.isShowlModal = true;
      this.logData = data;
    },
    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.getdetails();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.getdetails();
    },
  },
  beforeRouteLeave(to, from, next) {
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 

    if (to.name === 'attestReportList') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false;
    }
    //去下一页 缓存当前 在去下一页
    next();
  }
}
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content {
    padding: 28px 40px 20px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    overflow: hidden;

    .el-collapse-item {
      padding: 0 20px;
    }

    a {
      color: #546CFF;
    }

    .title {
      display: flex;

      div {
        margin-right: 50px;
      }
    }

    .appInfoBox {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: top;

      .textBox {
        width: 50%;
        margin-left: 20px;

        p {
          margin-bottom: 5px;
        }
      }

      .imgBox {
        .apkIcon {
          width: 80px;
          height: 80px;
        }

        .el-icon-picture-outline {
          font-size: 80px;
          margin-top: -5px;
        }
      }
    }

    .svg-review {
      width: 30px;
      height: 30px;
    }
  }
}

</style>
